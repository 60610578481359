var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"new-user-info"},[_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Title:","label-for":"title"}},[_c('ValidationProvider',{ref:"title",attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:(errors.length > 0 ? ' is-invalid' : ''),attrs:{"type":"text","placeholder":"Title"},model:{value:(_vm.shift.title),callback:function ($$v) {_vm.$set(_vm.shift, "title", $$v)},expression:"shift.title"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Choose Days:","label-for":"day"}},[_c('div',[_c('v-select',{attrs:{"options":_vm.days,"multiple":"","label":"en_title"},model:{value:(_vm.shift.days),callback:function ($$v) {_vm.$set(_vm.shift, "days", $$v)},expression:"shift.days"}})],1)])],1),_c('b-row',[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Start Time","label-for":"exampleInputtime"}},[_c('ValidationProvider',{ref:"icon",attrs:{"name":"icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exampleInputtime","type":"time"},model:{value:(_vm.shift.start),callback:function ($$v) {_vm.$set(_vm.shift, "start", $$v)},expression:"shift.start"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"end Time","label-for":"exampleInputtime"}},[_c('ValidationProvider',{ref:"icon",attrs:{"name":"icon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"exampleInputtime","type":"time"},model:{value:(_vm.shift.end),callback:function ($$v) {_vm.$set(_vm.shift, "end", $$v)},expression:"shift.end"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex  mt-4 align-items-center justify-content-between"},[_c('b-form-checkbox',{model:{value:(_vm.shift.is_active),callback:function ($$v) {_vm.$set(_vm.shift, "is_active", $$v)},expression:"shift.is_active"}},[_vm._v("Is Active")]),(_vm.edit)?_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Edit shift")]):_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Add New shift")])],1)],1)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }